<template>
  <ul class="grid grid-cols-1 gap-6 lg:gap-10 2xl:gap-86px sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
    <li
      class="col-span-1 bg-white rounded-large border-2 border-solid border-white"
      v-for="animal in animalTypes"
      :key="animal.type"
      :class="[
        animal.isActive ? 'border-brand-dark shadow-animalType' : ''
      ]"
      @click="toggle(animal)"
    >
      <!-- TODO: remove animal.disabled in click event, when logic will be done -->
      <div class="text-center pt-14 p-8">
        <img
          class="w-28 h-28 mx-auto"
          :src="getSelectedProduct === 'proact' ? animal.imgSrcProact : animal.imgSrc"
          :alt="animal.name"
        >
        <h3 class="mt-11 text-xl xl:text-2xl font-medium">
          {{ animal.name }}
          <span
              v-if="animal.tooltip"
              class="icon w-4 tooltip-block"
              v-html="icons.note"
              v-tooltip="animal.tooltip">
              </span>
        </h3>
      </div>
    </li>
  </ul>
</template>
<script>
import {mapMutations, mapState, mapGetters, mapActions} from 'vuex';

export default {
  name: 'AnimalType',

  created() {
    this.resetAnimalState()
  },

  methods: {
    ...mapMutations({
      setActiveAnimalType: 'animalType/setActiveAnimalType',
      setResultMatrix: 'result/setResultMatrix',
      setActiveDiet: 'feed/setActiveDiet'
    }),

    toggle(animal) {
      if (animal.id !== this.$store.getters['feed/getActiveDiet'].animal_id) {
        this.setActiveDiet({})
      }

      this.setActiveAnimalType(animal.type)
      this.setResultMatrix({})
    }
  },

  computed: {
    ...mapState('animalType', {
      animalTypes: state => state.animalTypes,
    }),

    ...mapGetters({
      getSelectedProduct: 'productSelect/getSelectedProduct'
    }),

    ...mapActions({
      resetAnimalState: 'animalType/resetState'
    })
  }
};
</script>

<style scoped>
>>> .tooltip-block svg {
  width: 16px;
  height: 16px;
}
</style>
