<template>
  <div class="home relative z-10">
    <header class="container-header">
      <h1 class="title">
        Select the animal type
      </h1>
    </header>

    <div class="bg-grey-lightest rounded-medium px-5 py-5 md:px-10 2xl:px-40 md:py-10 2xl:py-20">
      <AnimalType/>

      <Navigation
        v-bind="{
          prev: {disabled: true},
          next: {disabled: !getActiveAnimalType}
        }"
        @click="onNavigationClick($event, '', 'feed-view')"
      />
    </div>
  </div>
</template>

<script>
import Navigation from '@/components/Navigation';
import NavigationMixin from '@/mixins/NavigationMixin';
import AnimalType from '@/components/AnimalType';
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'Home',

  mixins: [NavigationMixin],

  components: {
    AnimalType,
    Navigation
  },

  created() {
    this.fetchSelectedProduct()
  },

  methods: {
    ...mapActions({
      fetchSelectedProduct: 'productSelect/fetchSelectedProduct'
    })
  },

  computed: {
    ...mapGetters({
      getActiveAnimalType: 'animalType/getActiveAnimalType',
      getSelectedProduct: 'productSelect/getSelectedProduct'
    }),
  },
}
</script>
